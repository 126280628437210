export default [
  {
    path: '/supports/add-credit',
    name: 'supports-add-credit',
    component: () => import('@/views/apps/supports/add-credit/SupportAddCredit.vue'),
    meta: {
      resource: 'support',
      action: 'manage',
    },
  },
  {
    path: '/supports/customers',
    name: 'supports-customers',
    component: () => import('@/views/apps/supports/customers/SupportCustomers.vue'),
    meta: {
      resource: 'support',
      action: 'manage',
    },
  },
  {
    path: '/supports/customers/:clientId',
    name: 'supports-customers-client-view',
    component: () => import('@/views/apps/supports/customers/SupportCustomersClickView.vue'),
    meta: {
      resource: 'support',
      action: 'manage',
    },
  },
  {
    path: '/supports/customers/:clientId/credit-transaction/:creditTransactionId',
    name: 'supports-customers-client-credit-transaction',
    component: () => import('@/views/apps/supports/customers/SupportCustomerCreditTransactionScreen.vue'),
    meta: {
      resource: 'support',
      action: 'manage',
    },
  },
]
