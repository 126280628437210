<template>
  <div class="container-image-preview">
    <img ref="refImageTag" :src="src" :alt="alt" @click="gZoomImage" @load="getImageSizeFromUrl" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    canZoom: {
      type: Boolean,
      default: true,
    },
    alt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      width: null,
      height: null,
    }
  },
  methods: {
    getImageSizeFromUrl() {
      const img = new Image()
      img.src = this.$refs.refImageTag.src
      img.onload = () => {
        this.width = img.width
        this.height = img.height

        this.$emit('changeSize', { width: img.width, height: img.height })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container-image-preview {
  width: 100%;
  height: 200px;
  background-color: #47526f;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    cursor: zoom-in;
  }
}
</style>
