import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import apps from './routes/apps'
import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import userManagementRouter from './routes/userManagementRouter.js'
import creditManagementMenu from './routes/CreditManagementMenu'
import NotificationManagementRouter from './routes/NotificationManagementRouter'
import wallpapers from './routes/wallpapers'
import slideDataRouter from './routes/SlideDataRouter'
// import detectImageRouter from './routes/DetectImageRouter'
import ImagesManagementRouter from './routes/imagesManagementRouter'
import UserTransactionDataRouter from './routes/UserTransactionDataRouter'
import PartnerBankRouter from './routes/PartnerBankRouter'
import SupportsRouter from './routes/supports-router'
import partnersManagementRouter from './routes/partners-management-router'
import withdrawManagementRoute from './routes/withdraw-management-route'
import exportManagementRouter from './routes/export-management-router'
import zodiacManagementRouter from './routes/zodiac-management-router'
import refundManagementRouter from './routes/refund-management-router'
import appMobileConfigRouter from './routes/app-mobile-config-router'
import redeemRouter from './routes/redeem-router'
import reportRouter from './routes/report-router'
import toolsRouter from './routes/tools-router'
import bookmarkManagementRouter from './routes/bookmark-management-router'
import affiliateRouter from './routes/affiliate-router'
import redeemCoinRouter from './routes/redeem-coin-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...userManagementRouter,
    ...partnersManagementRouter,
    ...creditManagementMenu,
    ...slideDataRouter,
    // ...detectImageRouter,
    ...zodiacManagementRouter,
    ...wallpapers,
    ...NotificationManagementRouter,
    ...ImagesManagementRouter,
    ...UserTransactionDataRouter,
    ...withdrawManagementRoute,
    ...exportManagementRouter,
    ...refundManagementRouter,
    ...appMobileConfigRouter,
    // ...apps,
    ...dashboard,
    ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    ...PartnerBankRouter,
    ...SupportsRouter,
    ...redeemRouter,
    ...redeemCoinRouter,
    ...reportRouter,
    ...toolsRouter,
    ...bookmarkManagementRouter,
    ...affiliateRouter,

    // {
    //   path: '/testx',
    //   name: 'testx',
    //   component: () => import('@/views/testx'),
    // },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

export default router
