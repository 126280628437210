<template>
  <b-modal
    ref="modalAdvanceSearch"
    size="lg"
    title="โปรดเลือกเงื่อนไข"
    hide-footer
    no-fade
  >
    <form ref="formAdvanceSearch" @submit="search">
      <div class="row">
        <div class="col-md-12">
          <div class="float-right m-b-15">
            <button type="button" class="btn btn-primary" @click="addForm">
              <i class="fas fa-plus"></i>
            </button>
            <button type="button" class="btn btn-danger" @click="delForm">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-for="(item, i) in searching" :key="i">
        <div class="col-md-2">
          <div class="form-group" v-if="i > 0">
            <select class="form-control" v-model="item.type">
              <option value="and">AND</option>
              <option value="or">OR</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <select
              class="form-control"
              v-model="item.name"
              @change="selectField($event, i)"
            >
              <option
                v-for="(option, i) in filterOptions(item.options, i)"
                :key="i"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <select class="form-control" v-model="item.operation">
              <option>ตัวเลือก</option>
              <option value="like" v-if="item.format !== 'date'"
                >คำคล้าย</option
              >
              <option
                v-if="item.format === 'date' || item.format === 'number'"
                value="range"
                >ช่วง</option
              >
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="<=">&lt;=</option>
            </select>
          </div>
        </div>
        <div class="col-md-5 value-box">
          <div v-if="item.operation == 'range'">
            <div v-if="item.format == 'date'">
              <DateRangePicker @input="val => inputDateRange(i, val)" />
            </div>
            <div v-else>
              <b-row>
                <b-col md="6">
                  <b-form-input
                    type="number"
                    @input="val => inputRange(1, i, val)"
                    placeholder="ค่าเริ่มต้น"
                    required
                  />
                </b-col>
                <b-col md="6">
                  <b-form-input
                    type="number"
                    @input="val => inputRange(2, i, val)"
                    placeholder="ค่าสิ้นสุด"
                    required
                  />
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-else-if="item.operation !== ''">
            <DatePicker
              @input="val => inputDate(i, val)"
              v-if="item['format'] == 'date'"
            />
            <b-form-input v-model="item['value']" required v-else />
          </div>
          <p class="mt-1 text-danger" v-if="item.error">{{ item.error }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="float-right">
            <b-button variant="secondary" @click="reset" class="mr-2">
              <i class="fa fa-redo"></i> รีเซ็ต
            </b-button>
            <button type="submit" class="btn btn-primary float-right"  v-bind:disabled="isDisableComputed">
              <i class="fa fa-search"></i> ค้นหา
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      searching: [],
      searchForm: {
        options: [{ label: 'กรุณาเลือก', value: '', type: '' }],
        name: '',
        value: '',
        operation: '',
        type: 'and',
        format: 'string',
        error: ''
      },
    }
  },
   computed: {
    isDisableComputed() {
       for(var i = 0;i < this.searching.length; i++ ) {
        if(this.searching[i].name===""||this.searching[i].value==="" ||this.searching[i].value===null) {
          return true;
        }
      }
    }
  },
  methods: {
    open(data) {
      this.$refs.modalAdvanceSearch.show()
      this.createForm(data)
    },
    close() {
      this.$refs.modalAdvanceSearch.hide()
    },
    selectField(event, i) {
      const value = $(event.target).val()
      this.searching[i].format = this.searching[i].options.find(
        item => item.value === value
      ).type
    },
    /**
     * ถ้ามี columns ไหนเคยถูกเลือกไปแล้ว
     * จะไม่เอามาให้เลือกซ้ำอีก
     */
    filterOptions(options, i) {
      const self = this
      return options
      return options.filter(option => {
        // ถ้าเป็น กรุณาเลือก ก็เป็น true เลย
        if (option.value == '') {
          return true
        }
        /**
         * ค้นหาอันที่ตรงกับใน searching ถ้ามีต้อง return false
         * เพราะว่าเราจะไม่เอาไปแสดงในตัวเลือก
         */
        return !self.searching.find((item, j) => {
          /**
           * ถ้าเป็น colums ของตำแหน่งตัวเอง ก็ให้มันเป็น false เลย
           * เพราะว่ามันจะต้องมีใน options ไม่งั้นมันจะหายไปเวลาเลือกแล้ว
           */
          if (j == i) return false
          return item.name === option.value
        })
      })
    },
    addForm() {
      if (this.searchForm.options.length - 1 == this.searching.length) {
        this.alertError('ไม่สามารถเพิ่มฟอร์มได้')
      } else {
        this.searching.push({ ...this.searchForm })
        const self = this
        setTimeout(function() {
          $('.value').on('change', function() {
            self.searching[$(this).attr('data-index')].value = $(this).val()
          })
        }, 500)
      }
    },
    delForm() {
      if (this.searching.length <= 1) {
        this.alertError('ไม่สามารถลบฟอร์มได้')
      } else {
        this.searching.pop()
      }
    },
    search(e) {
      e.preventDefault()
      this.$emit('search', this.searching)

    },
    reset() {
      this.searching = [{ ...this.searchForm }]
      this.$emit('search', [])
    },
    createForm(val) {
      this.searchForm.options = []
      val.map(item => {
        if (item.searchable === undefined) {
          this.searchForm.options.push({
            label: item.text,
            value: item.name,
            type: item.type,
            error: ''
          })
          if (this.searching.length == 0) {
            this.searching.push({ ...this.searchForm })
          }
        }
      })
    },
    inputRange(mode, i, val) {
      const oldVal = this.searching[i]['value'].split('-')
      this.searching[i]['error'] = ''
      if (mode === 1) {
        if (parseInt(val) > parseInt(oldVal[1])) {
          this.searching[i]['error'] =
            'จำนวนเริ่มต้นจะต้องน้อยกว่าหรือเท่ากับจำนวนสิ้นสุด'
        }
        this.searching[i]['value'] = val + '-' + (oldVal[1] ? oldVal[1] : '')
      } else {
        if (parseInt(oldVal[0]) > parseInt(val)) {
          this.searching[i]['error'] =
            'จำนวนเริ่มต้นจะต้องน้อยกว่าหรือเท่ากับจำนวนสิ้นสุด'
        }
        this.searching[i]['value'] = (oldVal[0] ? oldVal[0] : '') + '-' + val
      }
    },
    inputDate(i, val) {
      this.searching[i]['value'] = val.convertDate()
    },
    inputDateRange(i, val) {
      const temp = val.split('-')
      this.searching[i]['value'] =
        temp[0].convertDate() + '-' + temp[1].convertDate()
    }
  },
  watch: {
    data: {
      handler(val) {
        this.createForm(val)
      },
      deep: true
    }
  }
}
</script>

<style></style>
