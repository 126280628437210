<template>
  <b-modal ref="refModal" title="สรุปรายได้รูปทั้งหมด" centered hide-footer size="lg" @hide="resetState">
    <div v-for="(item, idx) in localListItem" :key="`list-preview-price-calculate-sub-wallpaper-${idx + 1}`">
      <b-row>
        <b-col sm="12" md="4">
          <div class="container-image-preview ct-cursor-zoom">
            <img :src="item.urlPreview" alt="img-preview" @click="gZoomImage" />
          </div>
        </b-col>
        <b-col>
          <h6 class="mt-1 mt-md-0">{{ item.name }}</h6>
          <div class="d-flex justify-content-between">
            <p class="m-0">
              ราคารูป:
            </p>
            <p class="m-0">{{ gFormatNumberToCurrency(item.price) }} บาท</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="m-0 text-danger">ค่าธรรมเนียม {{ revenueSharing }}%:</p>
            <p class="m-0 text-danger">
              {{ gFormatNumberToCurrency(cCalculateItemPriceAfterRevenueSharing(item.price)) }} บาท
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="m-0 text-danger">ค่าคอมมิชชั่น Affiliate {{ afCommissionRate }}%:</p>
            <p class="m-0 text-danger">
              {{ gFormatNumberToCurrency(cCalculateItemPriceAfterAFCommissionRate(item.price)) }} บาท
            </p>
          </div>
          <div class="d-flex justify-content-between mt-50">
            <h6 class="m-0 text-success">
              สรุปยอดที่ได้รับ:
            </h6>
            <h6 class="m-0 text-success">{{ gFormatNumberToCurrency(cCalculateTotalPrice(item.price)) }} บาท</h6>
          </div>
        </b-col>
      </b-row>
      <hr v-if="idx + 1 < localListItem.length" />
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      revenueSharing: 0,
      afCommissionRate: 0,
      localListItem: [
        // {
        //   name: '',
        //   urlPreview:
        //     'https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjUzNWJhdGNoMi1teW50LTQzLmpwZw.jpg',
        //   price: 100,
        // },
      ],
    }
  },
  computed: {
    cCalculateItemPriceAfterAFCommissionRate() {
      return price => Number(price * (this.afCommissionRate / 100))
    },
    cCalculateItemPriceAfterRevenueSharing() {
      return price => Number(price * (this.revenueSharing / 100))
    },
    cCalculateTotalPrice() {
      return price =>
        // eslint-disable-next-line implicit-arrow-linebreak, operator-linebreak
        price -
        (this.cCalculateItemPriceAfterAFCommissionRate(price) + this.cCalculateItemPriceAfterRevenueSharing(price))
    },
  },
  methods: {
    show(listItem, revenueSharing, afCommissionRate) {
      this.localListItem = [...listItem]
      this.revenueSharing = revenueSharing
      this.afCommissionRate = afCommissionRate
      this.$refs.refModal.show()
    },
    hide() {
      this.resetState()
      this.$refs.refModal.hide()
    },
    resetState() {
      this.revenueSharing = 0
      this.afCommissionRate = 0
      this.localListItem = []
    },
  },
}
</script>

<style lang="scss" scoped>
.container-image-preview {
  width: 100%;
  height: 100px;
  overflow: hidden;
  background-color: #47526f;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}
</style>
