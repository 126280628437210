export default [
  {
    path: '/affiliate/users-management',
    name: 'affiliate-user-management',
    component: () => import('@/views/apps/admins/affiliate/AFUserManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'manage',
    },
  },
  {
    path: '/affiliate/users-management/:id',
    name: 'affiliate-user-management-details',
    component: () => import('@/views/apps/admins/affiliate/AFUserManagementDetailsScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'manage',
      navActiveLink: 'affiliate-user-management',
    },
  },
  {
    path: '/affiliate/transactions',
    name: 'affiliate-transactions',
    component: () => import('@/views/apps/admins/affiliate/AFTransactionsScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'manage',
      navActiveLink: 'affiliate-transactions',
    },
  },
  {
    path: '/affiliate/withdraw-management',
    name: 'affiliate-withdraw-management',
    component: () => import('@/views/apps/admins/affiliate/AFWithdrawManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'manage',
    },
  },
]
