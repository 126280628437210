export default [
  {
    path: '/wallpapers',
    name: 'wallpapers',
    component: () => import('@/views/apps/wallpapers/wallpaperManagement/WallpaperManagementV2.vue'),
    meta: {
      resource: 'wallpaper',
      action: 'manage',
    },
  },
  {
    path: '/wallpapers/details/:id',
    name: 'wallpapers-details',
    component: () => import('@/views/apps/wallpapers/wallpaperManagement/WallpaperManagementDetails.vue'),
    meta: {
      resource: 'wallpaper',
      action: 'manage',
    },
  },
  {
    path: '/wallpapers/upload-wallpaper',
    name: 'wallpaper-upload-wallpaper',
    component: () => import('@/views/apps/wallpapers/uploadWallpaper/UploadWallpaper.vue'),
    meta: {
      resource: 'wallpaper',
      action: 'manage',
    },
  },
  {
    path: '/wallpapers/upload-zodiac',
    name: 'wallpaper-upload-zodiac',
    component: () => import('@/views/apps/wallpapers/uploadZodiac/UploadZodiac.vue'),
    meta: {
      resource: 'wallpaper',
      action: 'manage',
    },
  },

  {
    path: '/wallpapers/sale-info',
    name: 'wallpaper-sale-info',
    component: () => import('@/views/apps/Reports/SaleInfos'),
    meta: {
      resource: 'wallpaper',
      action: 'manage',
    },
  },
  {
    path: '/wallpapers/sale-info/view/:topic_image_data_id/:queryDataType/:date',
    name: 'wallpaper-sale-info-click-view',
    component: () => import('@/views/apps/Reports/SaleInfos/clickViewInfo'),
    meta: {
      resource: 'wallpaper',
      action: 'manage',
    },
  },
  {
    path: '/wallpapers/create',
    name: 'wallpaper-create',
    component: () => import('@/views/apps/wallpapers/wallpaperCreate/WallpaperCreate.vue'),
    meta: {
      resource: 'admin',
      action: 'manage',
    },
  },
  {
    path: '/wallpapers/setting-upload',
    name: 'wallpaper-setting-upload',
    component: () => import('@/views/apps/wallpapers/setting-upload-wallpaper/SettingUploadWallpaperScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
]
