/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Vue from 'vue'
const self = Vue
// axios
import axios from '@/libs/axios'

export default function(Vue) {
  Vue.api = {
    async get(url) {
      return await axios.get(url)
    },
    async post(url, request, useAlert = false) {
      localStorage.setItem('axAlert', useAlert)

      const data = await axios.post(url, request).catch(() => '')
      return Promise.resolve(data)
    },
    async patch(url, request) {
      try {
        const {
          data: { data },
        } = await axios.patch(url, request)
        return Promise.resolve(data)
      } catch (error) {
        debugger
        return Promise.reject(error)
      }
    },
    async del(url, request, confirmDelete = false) {
      try {
        /**
         * Overwrite data
         */
        request = { params: request }

        if (confirmDelete) {
          const result = await Vue.swal({
            title: 'are you sure want to delete',
            text: '',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          /**
           * If Confirm Delete data
           */
          Vue.swal({
            allowOutsideClick: false,
            showConfirmButton: false,
            title: 'โปรดรอสักครู่...',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          if (result.value) {
            const resp = await axios.delete(`${url}/${request.params.id}`).catch(() => '')

            await Vue.swal({
              icon: 'success',
              title: 'Successfully deleted',
              // text: 'Your file has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            return Promise.resolve(data)
          } else {
            return Promise.reject('')
          }
        }
        let {
          data: { data, errors },
        } = await axios.delete(url, request)
        return Promise.resolve(data)
      } catch (error) {
        if (error?.response?.headers?.requestid) {
          $.gritter.add({
            title: error?.response?.headers?.requestid,
            text: url,
            time: null,
            class_name: 'my-sticky-class',
          })
        }
        console.error(error)

        return Promise.reject(error)
      } finally {
        Vue.swal().close()
      }
    },
    async deleteV2(url, vueInstance, confirmDelete = true, confirmMessage) {
      if (!vueInstance) return null
      if (confirmDelete) {
        const titleConfirm = confirmMessage?.title ?? 'ยืนยันลบข้อมูล'
        const bodyConfirm = confirmMessage?.body ?? 'โปรดกดยืนยันอีกครั้ง เพื่อดำเนินการต่อ'

        const isConfirm = await vueInstance.gCheckConfirmV1(titleConfirm, bodyConfirm)

        if (!isConfirm) return null
      }

      vueInstance.gOpenPageLoading()

      try {
        const resp = await axios.delete(url)
        return resp
      } catch (error) {
        return null
      } finally {
        vueInstance.gClosePageLoading()
      }
    },
    async deleteV3(url, vueInstance, useAlert = true) {
      const resp = await axios.delete(url, { customResponse: true }).catch(() => null)

      if (useAlert && resp?.code === 422) {
        Object.values(resp.data).forEach(ms => {
          // console.log('error ms', ms[0])
          vueInstance.gDisplayToast('ดำเนินการไม่สำเร็จ', ms[0], 'danger')
        })
      }

      return resp
    },
    async createOrUpdate(url, form, useAlert = true) {
      localStorage.setItem('axAlert', useAlert)
      let resources = ''
      if (!form.id) {
        resources = await axios.post(url, form).catch(() => Promise.reject(''))
      } else {
        const formId = form['id']
        /**
         * ตรวจสอบว่ามี / มาหรือไม่
         */
        if (!(url.slice(-1) === '/')) {
          url = `${url}/${formId}`
        } else {
          url += formId
        }
        resources = await axios.patch(url, form).catch(() => Promise.reject(''))
      }
      return resources.original?.data ?? resources
    },
    async postV2(url, request, vueInstance, useAlert = true) {
      const resp = await axios.post(url, request, { customResponse: true }).catch(() => null)

      if (useAlert && resp?.code === 422) {
        Object.values(resp.data).forEach(ms => {
          // console.log('error ms', ms[0])
          vueInstance.gDisplayToast('ดำเนินการไม่สำเร็จ', ms[0], 'danger')
        })
      }

      return resp
    },
    async getV2(url, configs = {}) {
      return axios.get(url, { customResponse: true, ...configs })
    },
    async putV2(url, request, vueInstance, useAlert = true) {
      const resp = await axios.put(url, request, { customResponse: true }).catch(() => null)

      if (useAlert && resp?.code === 422) {
        Object.values(resp.data).forEach(ms => {
          // console.log('error ms', ms[0])
          vueInstance.gDisplayToast('ดำเนินการไม่สำเร็จ', ms[0], 'danger')
        })
      }

      return resp
    },
    async patchV2(url, request, vueInstance, useAlert = true) {
      const resp = await axios.patch(url, request, { customResponse: true }).catch(() => null)

      if (useAlert && resp?.code === 422) {
        Object.values(resp.data).forEach(ms => {
          // console.log('error ms', ms[0])
          vueInstance.gDisplayToast('ดำเนินการไม่สำเร็จ', ms[0], 'danger')
        })
      }

      return resp
    },
  }
  Object.defineProperties(Vue.prototype, {
    api: {
      get() {
        return Vue.api
      },
    },
  })
}
