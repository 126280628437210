<template>
  <div>
    <div class="d-flex justify-content-center mb-1" style="height: 200px; align-items: center;" v-if="loading">
      <b-spinner label="Loading..." />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>
