import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import wallpaperStore from './wallpaperStore'
import dashboardDataStore from './DashboardDataStore'
import LoadingStore from './Loading'
// import UserInfoStore from './UserInfoStore'
import AuthStore from './AuthStore'
import appMobilePublicConfigs from './appMobilePublicConfigs'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    wallpapers: wallpaperStore,
    dashboardDataStore: dashboardDataStore,
    LoadingStore,
    // UserInfoStore,
    AuthStore,
    'app-mobile-public-configs': appMobilePublicConfigs,
  },
  strict: process.env.DEV,
})
