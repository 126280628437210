import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue, VBTooltip } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import DataTable from 'laravel-vue-datatable'
import VueThailandAddress from 'vue-thailand-address'
import 'vue-thailand-address/dist/vue-thailand-address.css'
import Ripple from 'vue-ripple-directive'
import 'viewerjs/dist/viewer.css'

import VueViewer from 'v-viewer'

Vue.use(VueViewer)
Vue.use(DataTable)
Vue.use(VueThailandAddress)
Vue.directive('ripple', Ripple)
Vue.directive('b-tooltip', VBTooltip)

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

Vue.use(BootstrapVue)
// Global Components
import './global-components'
import './../GlobalMixin'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import './load-widgets'
import VueApexCharts from 'vue-apexcharts'

// Axios Mock Adapter
import '@/@fake-db/db'

/**
 * How to use
 * this.$date()
 */
import './Dayjs'

// format Library
import formater from './formater'

// API Library
import api from './api'
Vue.use(api)

// format Library
Vue.use(formater)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
// const app = createApp({})
// app.component('BCardCode', () => import('@core/components/b-card-code/BCardCode.vue'))

Vue.use(VueApexCharts)
window.Apex.chart = { fontFamily: 'Kanit' }

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
