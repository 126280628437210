<template>
  <b-card>
    <BoxContainerLoading :loading="_loading" />
    <slot v-if="!_loading" name="header-table" />
    <div
      class="custom-search d-flex justify-content-end"
      v-if="newAble"
    >
      <div
        class="d-flex align-items-center mb-1"
        v-if="!_loading"
      >
        <b-button
          @click="$emit('clickNew','')"
          variant="outline-info"
        >
          {{btnText}}
        </b-button>
      </div>

    </div>
    <vue-good-table
      v-if="!_loading"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
    enabled: true,
    perPage:pageLength
  }"
      :rows="rows"
      :columns="_columns"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Custom -->
        <span v-if="props.column.type === 'custom'">
          <slot :name="props.column.field" :row="props.row">
            <pre>{{ props.row }}</pre>
          </slot>
        </span>

        <!-- Column: Name -->
        <span
          v-else-if="props.column.field === 'fullName1'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.type === 'number'">
          {{ withCommas(props.row[props.column.field]) }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <!--  click view data -->
              <b-dropdown-item
                @click="$emit('clickView',props.row)"
                v-if="viewAble"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>ดูข้อมูล</span>
              </b-dropdown-item>

              <!-- click edit -->
              <b-dropdown-item
                @click="$emit('clickEdit',props.row)"
                v-if="editAble"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>แก้ไขข้อมูล</span>
              </b-dropdown-item>

              <!-- click delete -->
              <b-dropdown-item
                @click="$emit('clickDelete',props.row)"
                v-if="deleteAble"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>ลบข้อมูล</span>
              </b-dropdown-item>

              <!-- click select -->
              <b-dropdown-item
                @click="$emit('clickSelect',props.row)"
                v-if="selectAble"
              >
                <feather-icon
                  icon="SendIcon"
                  class="mr-50"
                />
                <span>{{ selectAbleText }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="loadingContent">
        <div class="text-center">
          <b-spinner variant="primary" />
        </div>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store/index'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
export default {
  props: {
    items: [],
    title: String,
    pParams: [],
    btnText: {
      type: String,
      default: 'เพิ่มข้อมูล',
    },
    url: String,
    method: {
      type: String,
      default: 'get',
    },
    viewAble: {
      type: Boolean,
      default: false,
    },
    editAble: {
      type: Boolean,
      default: false,
    },
    deleteAble: {
      type: Boolean,
      default: false,
    },
    newAble: {
      type: Boolean,
      default: false,
    },
    selectAble: {
      type: Boolean,
      default: false,
    },
    selectAbleText: {
      type: String,
      default: 'เลือก',
    },
    columns: {
      type: Array,
      default() {
        return [
          {
            label: 'Name1',
            field: 'fullName',
          },
          {
            label: 'Email',
            field: 'email',
          },
          {
            label: 'Date',
            field: 'startDate',
          },
          {
            label: 'Salary',
            field: 'salary',
          },
          {
            label: 'Status',
            field: 'status',
          },
          {
            label: 'Action',
            field: 'action',
          },
        ]
      },
    },
  },
  components: {
    VueGoodTable,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  computed: {
    _loading() {
      return this.pageLoading
    },
    _columns() {
      return this.columns.map(data => {
        if (!data.label || data.label == 'action') {
          data.sortable = false
          return data
        }
        return {
          ...data,
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        }
      })
    },
  },
  data() {
    return {
      pageLoading: false,
      pageLength: 10,
      isLoading: false,
      searchTerm: '',

      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },
  created() {
    this.pageLoading = true

    this.loadItems()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      // console.log(params)
      this.updateParams({
        sort: [
          {
            type: params[0].type === 'none' ? 'asc' : params[0].type,
            field: params[0].field,
          },
        ],
        // sort: [
        //   {
        //     type: params[0].type,
        //     field: this.columns[0].field,
        //   },
        // ],
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async reloading() {
      this.loadItems()
    },

    // load items is what brings back the rows from server
    loadItems() {
      const params = { ...this.serverParams, filter: this.searchTerm }
      this.api
        .post(this.url, params)
        .then(response => {
          this.totalRecords = response.total
          this.rows = response.data
          this.pageLoading = false
        })
        .catch(err => {
          this.pageLoading = false
        })
    },
  },
}
</script>
<style lang="scss" >
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>