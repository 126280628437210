<template>
  <b-overlay :show="loading">
    <b-card>
      <div class="mb-1 d-flex justify-content-between align-items-sm-center flex-column flex-sm-row">
        <h4 class="mb-1 mb-sm-0">{{ title }}</h4>
        <div class="d-flex justify-content-end">
          <b-form-group>
            <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
          </b-form-group>
        </div>
      </div>

      <slot name="cardHeader" />
      <vue-good-table
        :line-numbers="false"
        :columns="cColumns"
        :rows="items"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.type === 'custom'">
            <slot :name="props.column.field" :row="props.row">
              <pre>{{ props.row }}</pre>
            </slot>
          </span>

          <span v-else-if="props.column.type === 'currency'">
            {{ gFormatNumberToCurrency(props.row[props.column.field] || 0) }}
          </span>

          <span v-else-if="props.column.field === 'actions'">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="text-decoration-none"
              menu-class="custom-dropdown-menu-container"
            >
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>

              <div v-for="(action, actionIndex) in actions" :key="actionIndex">
                <b-dropdown-item v-if="checkRenderAction(action, props.row)" @click="$emit(action.keyEmit, props.row)">
                  <feather-icon :icon="action.iconName" class="mr-50" :class="action.classIcon" />
                  <span>{{ action.label }}</span>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap px-50 pb-50">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                แสดง 1 ถึง
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50']"
                class="mx-1"
                @input="value => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap"> จากทั้งหมด {{ props.total }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </b-overlay>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  components: { VueGoodTable },
  props: {
    actions: {
      type: Array,
      default: () => [], // {keyEmit: '',label: '',iconName: ''}
    },
    items: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pageLength: 10,
      searchTerm: '',
    }
  },
  computed: {
    cColumns() {
      const c = [...this.columns]
      if (this.actions.length > 0) {
        c.push({
          field: 'actions',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'text-center',
          globalSearchDisabled: true,
        })
      }
      return c
    },
  },
  methods: {
    checkRenderAction(action, row) {
      if (action.canShowFn && typeof action.canShowFn === 'function') {
        return action.canShowFn(row) ?? false
      }

      return true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
.custom-dropdown-menu-container {
  max-height: 100px;
  overflow-y: auto;
}
</style>
