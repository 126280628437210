<template>
  <div class="container-upload">
    <p>ลากวางหรือคลิกเพื่ออัพโหลด</p>
    <input ref="refInputUpload" type="file" accept="image/jpeg, image/png" @input="onUpload($event)" />
  </div>
</template>

<script>
export default {
  methods: {
    async onUpload(event) {
      const file = event.target.files[0]
      //   console.log('[uploadImageWidget] onUpload', file)
      if (!file) return

      const fileSizeMB = Number(file.size / (1024 * 1024)).toFixed(2)
      file.fileSizeMB = fileSizeMB

      const blobUrl = URL.createObjectURL(file)

      this.$emit('uploadFile', { file, blobUrl })

      this.$refs.refInputUpload.value = null
    },
  },
}
</script>

<style lang="scss" scoped>
.container-upload {
  width: 100%;
  height: 100px;
  background-color: whitesmoke;
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(187, 185, 185);
  cursor: pointer;
  p {
    margin: 0;
  }
  input {
    width: 100%;
    height: 100%;
    border: 1px solid red;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
