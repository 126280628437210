import Vue from 'vue'

export default function(Vue) {
  Vue.csFormat = {
    formatDate(date, showTime = true) {
      if (showTime) {
        return new Date(date).toLocaleDateString('th-TH', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      }
      return new Date(date).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },
    formatStatusBadge(status) {
      switch (status) {
        case 'approve':
          return 'อนุมัติ'
        case 'approved':
          return 'อนุมัติ'
        case 'reject':
          return 'ไม่อนุมัติ'
        case 'wait':
          return 'รออนุมัติ'
        case 'checking':
          return 'กำลังตรวจสอบ'
        default:
          return status
      }
    },
    formatVariantStatusBadge(status) {
      switch (status) {
        case 'approve':
          return 'success'
        case 'approved':
          return 'success'
        case 'reject':
          return 'danger'
        case 'wait':
          return 'secondary'
        case 'checking':
          return 'warning'
        default:
          return ''
      }
    },
    name() {
      return 'this is global csFormat'
    },
  }

  Object.defineProperties(Vue.prototype, {
    csFormat: {
      get() {
        return Vue.csFormat
      },
    },
  })
}
