<template>
  <div>
    <app-timeline>
      <app-timeline-item
        v-for="review in listData"
        :key="review.id"
        :variant="csFormat.formatVariantStatusBadge(review.status_data)"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1">
          <b-badge :variant="csFormat.formatVariantStatusBadge(review.status_data)">
            {{ csFormat.formatStatusBadge(review.status_data) }}
          </b-badge>
          <small class="text-muted">{{ gFormatDate(review.created_at, true) }}</small>
        </div>
        <p>รีวิวโดย {{ maskNameReview || review.action_by_data.name }}</p>
        <p>รายละเอียด : {{ review.note }}</p>
        <b-row cols="1" cols-md="2">
          <b-col v-for="(img, index) in review.image_datas" :key="index">
            <box-image-preview-widget :src="img.outside_url" />
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: { AppTimeline, AppTimelineItem },
  props: {
    listData: {
      type: Array,
      default() {
        return []
      },
    },
    maskNameReview: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.image-preview-reject-detail {
  width: 100%;
}
</style>
