export default [
  {
    path: '/redeem-coin-management',
    name: 'redeem-coin-management',
    component: () => import('@/views/apps/admins/redeem-coin-management/RedeemCoinManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
  {
    path: '/redeem-coin-management/details/:redeemMainId',
    name: 'redeem-coin-management-details',
    component: () => import('@/views/apps/admins/redeem-coin-management/RedeemCoinManagementDetailsScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
