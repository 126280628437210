export default [
  {
    path: '/report/credit',
    name: 'report-credit',
    component: () => import('@/views/apps/admins/report/ReportCreditScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/report/user-login',
    name: 'report-user-login',
    component: () => import('@/views/apps/admins/report/ReportUserLoginScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/report/user-login/country-details/:code',
    name: 'report-user-login-country-details',
    component: () => import('@/views/apps/admins/report/ReportUserLoginCountryDetailsScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/report/survey',
    name: 'report-survey',
    component: () => import('@/views/apps/admins/report/ReportSurveyScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/report/providers-transaction',
    name: 'report-providers-transaction',
    component: () => import('@/views/apps/admins/report/ReportProvidersTransactionScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
]
