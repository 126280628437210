import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 600000,
  // headers: {'X-Custom-Header': 'foobar'}
})
const FNalert = (data, title = 'Validate', variant = 'danger') => {
  const useAlert = localStorage.getItem('axAlert')

  const vm = new Vue()
  const h = vm.$createElement

  if (useAlert == true || useAlert == 'true') {
    const errorDesc = h(
      'div',
      {},
      Object.values(data).map(ms => {
        return h(
          'div',
          {},
          ms.map(m => h('div', {}, m)),
        )
      }),
    )
    vm.$bvToast.toast([errorDesc], {
      title: title,
      variant: variant,
      solid: true,
    })
  }

  return Promise.reject(data)
}

axiosIns.interceptors.response.use(
  response => {
    // console.log('axios resp', response)

    if (response.config?.customResponse === true) {
      if (response.data?.code === 405 && response.data?.data === 'access is denied') {
        window.location.replace('/pages/miscellaneous/not-authorized')
      }
      return Promise.resolve(response.data)
    }

    const {
      data: { code, data },
      config: { method },
    } = response
    switch (code) {
      case 422:
        return FNalert(data)
      case 403:
        return FNalert({ user: [data] })
      case 405:
        if (data === 'access is denied') {
          window.location.replace('/pages/miscellaneous/not-authorized')
        }
        return Promise.resolve(data)
      default:
        const useAlert = localStorage.getItem('axAlert')
        if (method == 'post' && useAlert == 'true') {
          FNalert({ user: [data] }, 'แจ้งเตือน', 'success')
        }
        if (method == 'patch' && useAlert == 'true') {
          FNalert({ user: ['แก้ไข ข้อมูลสำเร็จ'] }, 'แจ้งเตือน', 'warning')
        }
        return Promise.resolve(data)
    }
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.clear()
      window.location = '/login'
    }
    return Promise.reject('')
  },
)

Vue.prototype.$http = axiosIns
Vue.prototype.$axios = axiosIns
export default axiosIns
