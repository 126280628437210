import Vue from 'vue'
import router from '@/router'

const displayAlert = (data = '', title = 'พบข้อผิดพลาด', variant = 'danger') => {
  const vm = new Vue()
  const h = vm.$createElement

  vm.$bvToast.toast(h('div', {}, data), {
    title,
    variant,
    solid: true,
  })
}

const initialState = () => {
  return {
    imagePreview: null,
    loading: false,
    type: 'upload',
    wallpaperDatas: {
      allImage: [],
      mainImage: {
        title: '',
        details: '',
        revenue_sharing: 0,
        file: null,
        zodiac: null,
        youtube_link: '',
        blog_link: '',
        wallpaper_tags: [],
        hint_modal_free_image: '',
        hide_in_store: false,
        defaultValuePositionList: [],
        positionList: [],
        affiliate: {
          commission_rate: 0,
          join_status: false,
          isDeducted: false,
        },
        id: '',
        optionsFont: {
          TH: {
            name: null,
            style: null,
            options: [],
          },
          EN: {
            name: null,
            style: null,
            options: [],
          },
        },
        title_translations: {
          th: '',
        },
        details_translations: {
          th: '',
        },
        hint_modal_free_image_translations: {
          th: '',
        },
      },
      reviewTimeline: [],
      statusType: 'none',
      user: null,
    },
    isHasOldData: false,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isNewWallpaperBeforeReview(state) {
      return state.wallpaperDatas.reviewTimeline.length === 0
    },
    isEditMainImageTitle(state) {
      return keyTranslation => {
        if (!state.isHasOldData) return false
        // console.log('isEditMainImageTitle', keyTranslation)

        return (
          `${state.wallpaperDatas.mainImage.title_translations[keyTranslation]}` !==
          `${state.wallpaperDatas.mainImage.oldTitleTranslations[keyTranslation]}`
        )
      }
    },
    isEditMainImageDetails(state) {
      return keyTranslation => {
        if (!state.isHasOldData) return false
        // console.log('isEditMainImageDetails', keyTranslation)

        return (
          `${state.wallpaperDatas.mainImage.details_translations[keyTranslation]}` !==
          `${state.wallpaperDatas.mainImage.oldDetailsTranslations[keyTranslation]}`
        )
      }
    },
    isEditMainImageHintModalFreeImage(state) {
      return keyTranslation => {
        if (!state.isHasOldData) return false
        // console.log('isEditHintModalFreeImage', keyTranslation)

        return (
          `${state.wallpaperDatas.mainImage.hint_modal_free_image_translations[keyTranslation]}` !==
          `${state.wallpaperDatas.mainImage.oldHintModalFreeImageTranslations[keyTranslation]}`
        )
      }
    },
    isEditMainImageHideInStore(state) {
      if (!state.isHasOldData) return false
      return state.wallpaperDatas.mainImage.hide_in_store !== state.wallpaperDatas.mainImage.oldHideInStore
    },
    isEditMainImageWallpaperTags(state) {
      if (!state.isHasOldData) return false
      const { wallpaper_tags, oldWallpaperTags } = state.wallpaperDatas.mainImage

      if (wallpaper_tags.length !== oldWallpaperTags.length) return true

      const keyIdObj = {}

      wallpaper_tags.forEach(tag => {
        keyIdObj[tag.id] = ''
      })

      let isAllSame = true

      for (let i = 0; i < oldWallpaperTags.length; i += 1) {
        const tag = oldWallpaperTags[i]

        const isFoundIdInKeyObj = tag.id in keyIdObj

        if (!isFoundIdInKeyObj) {
          isAllSame = false
          break
        }
      }

      return !isAllSame
    },
    isEditMainImageYouTubeLink(state) {
      if (!state.isHasOldData) return false

      return state.wallpaperDatas.mainImage.youtube_link !== state.wallpaperDatas.mainImage.oldYoutubeLink
    },
    isEditMainImageBlogLink(state) {
      if (!state.isHasOldData) return false

      return state.wallpaperDatas.mainImage.blog_link !== state.wallpaperDatas.mainImage.oldBlogLink
    },
    isEditMainImageZodiac(state) {
      if (!state.isHasOldData) return false

      const { zodiac, oldZodiac } = state.wallpaperDatas.mainImage

      if (zodiac?.id && oldZodiac?.id) {
        return zodiac.id !== oldZodiac.id
      }

      return zodiac !== oldZodiac
    },
    isEditMainImageOptionsFontFamily(state) {
      return keyFont => {
        if (!state.isHasOldData) return false
        if (!keyFont) return true

        const { optionsFont, oldOptionsFont } = state.wallpaperDatas.mainImage
        const newFontFamily = optionsFont[keyFont]?.name?.family ?? ''
        const oldFontFamily = oldOptionsFont[keyFont]?.name?.family ?? ''

        return newFontFamily !== oldFontFamily
      }
    },
    isEditMainImageOptionsFontStyle(state) {
      return keyFont => {
        if (!state.isHasOldData) return false
        if (!keyFont) return true
        const { optionsFont, oldOptionsFont } = state.wallpaperDatas.mainImage

        const newFontStyle = optionsFont[keyFont]?.style?.font ?? ''
        const oldFontStyle = oldOptionsFont[keyFont]?.style?.font ?? ''

        return newFontStyle !== oldFontStyle
      }
    },
    isEditMainImageAffiliateJoinStatus(state) {
      if (!state.isHasOldData) return false
      return (
        state.wallpaperDatas.mainImage.affiliate.join_status !== state.wallpaperDatas.mainImage.oldAffiliate.join_status
      )
    },
    isEditMainImageAffiliateCommissionRate(state) {
      if (!state.isHasOldData) return false
      return (
        state.wallpaperDatas.mainImage.affiliate.commission_rate !==
        state.wallpaperDatas.mainImage.oldAffiliate.commission_rate
      )
    },
  },
  mutations: {
    RESET_WALLPAPER_STATE(state) {
      Object.assign(state, initialState())
    },
    UPDATE_ALL_IMAGE(state, data) {
      state.allImage = data
    },
    SET_DATA_EDIT_IMAGE(state, { data, navigate = true, oldData = null }) {
      // console.log('SET_DATA_EDIT_IMAGE', {
      //   newData: data,
      //   oldData,
      // })

      if (!data.json_data) {
        displayAlert('ไม่พบข้อมูลเก่าของรูปวอลเปเปอร์นี้')
      } else {
        const mainImage = JSON.parse(data.json_data)
        mainImage.file.previewUrl = data.image_preview
        mainImage.id = data.id ? data.id : ''

        mainImage.youtube_link = mainImage.youtube_link ?? ''
        mainImage.blog_link = mainImage.blog_link ?? ''
        mainImage.wallpaper_tags = data?.wallpaper_tags ?? []
        mainImage.hide_in_store = data?.hide_in_store ?? false

        mainImage.affiliate = {
          join_status: data?.affiliate?.join_status ?? false,
          commission_rate: data?.affiliate?.commission_rate ?? 0,
          isDeducted: data?.affiliate?.isDeducted ?? false, // เช็คว่ารูปเคยถูกหัก af หรือยัง
        }

        mainImage.revenue_sharing = Number(data?.user?.revenue_sharing || 0)

        mainImage.title_translations = { th: mainImage.title, ...data?.title_translations }
        mainImage.details_translations = { th: mainImage.details, ...data?.details_translations }

        mainImage.hint_modal_free_image = mainImage?.hint_modal_free_image ?? ''
        mainImage.hint_modal_free_image_translations = {
          th: mainImage.hint_modal_free_image,
          ...data?.hint_modal_free_image_translations,
        }

        const isHasOldDataFoundAndShowHistoryData = oldData && data.image_timeline.length > 0

        // เช็คว่ามีประวัติของรูปเก่าที่เคยถูกส่งรีวิวไหม
        if (isHasOldDataFoundAndShowHistoryData) {
          const oldMainImage = JSON.parse(oldData.json_data)
          mainImage.oldFile = { ...oldMainImage.file, previewUrl: oldData.image_preview }

          mainImage.oldYoutubeLink = oldMainImage.youtube_link ?? ''
          mainImage.oldBlogLink = oldMainImage.blog_link ?? ''
          mainImage.oldWallpaperTags = oldData.wallpaper_tags ?? []
          mainImage.oldHideInStore = oldData.hide_in_store ?? false

          mainImage.oldTitleTranslations = { th: oldMainImage.title, ...oldData?.title_translations }
          mainImage.oldDetailsTranslations = { th: oldMainImage.details, ...oldData?.details_translations }

          mainImage.oldHintModalFreeImage = oldMainImage.hint_modal_free_image ?? ''
          mainImage.oldHintModalFreeImageTranslations = {
            th: oldMainImage.hint_modal_free_image,
            ...oldData?.hint_modal_free_image_translations,
          }

          mainImage.oldZodiac = oldMainImage.zodiac
          mainImage.oldDefaultValuePositionList = oldMainImage.defaultValuePositionList
          mainImage.oldPositionList = oldMainImage.positionList
          mainImage.oldOptionsFont = oldMainImage.optionsFont

          mainImage.oldAffiliate = {
            join_status: oldData?.affiliate?.join_status ?? false,
            commission_rate: oldData?.affiliate?.commission_rate ?? 0,
          }

          state.isHasOldData = true
        }

        const allImage = []

        data.topic_image_list_datas.forEach(val => {
          let currentOldSubImage = null

          if (isHasOldDataFoundAndShowHistoryData) {
            currentOldSubImage = oldData.topic_image_list_datas.find(v => v.topic_image_list_data_id === val.id)
          }

          // console.log('item in all image old', { id: val.id, newValue: val, oldValue: currentOldSubImage })

          const oldOptions = currentOldSubImage
            ? {
                dayColor: currentOldSubImage.name ?? null,
                fontColor: currentOldSubImage.color_code_data_id,
                price: Number(currentOldSubImage.price),
                title: currentOldSubImage.title ?? '',
                detail: currentOldSubImage.detail ?? '',
                watermarkColor: currentOldSubImage.watermark_color ?? 'black',
                title_translations: {
                  th: currentOldSubImage.title,
                  ...currentOldSubImage?.title_translations,
                },
                details_translations: {
                  th: currentOldSubImage.detail,
                  ...currentOldSubImage?.details_translations,
                },
              }
            : null

          const obj = {
            file: {
              localId: null,
              naturalHeight: mainImage.file.naturalHeight,
              naturalWidth: mainImage.file.naturalWidth,
              previewUrl: val.image_preview,
            },
            localId: null,
            id: val.id,
            options: {
              dayColor: val.name ?? null,
              fontColor: val.color_code_data_id,
              price: Number(val.price),
              title: val.title ?? '',
              detail: val.detail ?? '',
              watermarkColor: val.watermark_color ?? 'black',
              title_translations: {
                th: val.title,
                ...val?.title_translations,
              },
              details_translations: {
                th: val.detail,
                ...val?.details_translations,
              },
            },
            oldOptions,
            ai_generate_data: val?.ai_generate_data,
            tempIsHasOldDataFound: !!currentOldSubImage,
          }
          allImage.push(obj)
        })

        state.wallpaperDatas.reviewTimeline = [...data.image_timeline]
        state.wallpaperDatas.allImage = allImage
        state.wallpaperDatas.mainImage = mainImage
        state.wallpaperDatas.statusType = data.approved_status ?? 'none'
        state.wallpaperDatas.user = { ...data.user }
        if (navigate) {
          router.push({ name: 'wallpaper-upload-wallpaper' })
        }
      }
    },
    UPDATE_MAIN_POSITION_LIST(state, data) {
      // console.log('UPDATE_MAIN_DEFAULT_POSITION_LIST', data)
      state.wallpaperDatas.mainImage.positionList = [...data]
    },
    UPDATE_MAIN_WALLPAPER_REVENUE_SEARING(state, data) {
      state.wallpaperDatas.mainImage.revenue_sharing = data
    },
  },
  actions: {},
}
