export default [
  {
    path: '/zodiac-management',
    name: 'zodiac-management',
    component: () => import('@/views/apps/admins/zodiac-management/ZodiacManagementScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/zodiac-management/:id',
    name: 'zodiac-management-details',
    component: () => import('@/views/apps/admins/zodiac-management/ZodiacManagementDetailsScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
]
