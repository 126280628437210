<template>
  <b-card :title="title">
    <!-- button  -->
    <div class="custom-search d-flex justify-content-end" v-if="newAble">
      <div class="d-flex align-items-center mb-1">
        <b-button @click="$emit('clickNew', '')" variant="outline-info">
          {{ btnText }}
        </b-button>
      </div>
    </div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>
    <b-overlay :show="loading">
      <!-- table -->
      <vue-good-table
        :line-numbers="false"
        :columns="_columns"
        :rows="_rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <slot :name="props.column.label" :props="props">
            <span>
              {{ props.column.label }}
            </span>
          </slot>
        </template>

        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Custom status by type -->
          <span v-else-if="props.column.type === 'status'">
            <b-badge :variant="props.column.renderVariant(props)">
              {{ props.formattedRow[props.column.field] }}
            </b-badge>
          </span>

          <span v-else-if="props.column.type === 'number'">
            <span class="text-nowrap">{{ gFormatNumberToCurrency(props.row[props.column.field]) }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>

                <!--  click view data -->
                <b-dropdown-item @click="$emit('clickView', props.row)" v-if="viewAble">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>ดูข้อมูล</span>
                </b-dropdown-item>

                <!-- click edit -->
                <b-dropdown-item @click="$emit('clickEdit', props.row)" v-if="editAble">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>แก้ไขข้อมูล</span>
                </b-dropdown-item>

                <!-- click delete -->
                <b-dropdown-item @click="$emit('clickDelete', props.row)" v-if="deleteAble">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>ลบข้อมูล</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50']"
                class="mx-1"
                @input="value => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
export default {
  props: {
    items: [],
    title: String,
    btnText: {
      type: String,
      default: 'เพิ่มข้อมูล',
    },
    url: String,
    method: {
      type: String,
      default: 'get',
    },
    viewAble: {
      type: Boolean,
      default: false,
    },
    editAble: {
      type: Boolean,
      default: false,
    },
    deleteAble: {
      type: Boolean,
      default: false,
    },
    newAble: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
        return [
          {
            label: 'Name1',
            field: 'fullName',
          },
          {
            label: 'Email',
            field: 'email',
          },
          {
            label: 'Date',
            field: 'startDate',
          },
          {
            label: 'Salary',
            field: 'salary',
          },
          {
            label: 'Status',
            field: 'status',
          },
          {
            label: 'Action',
            field: 'action',
          },
        ]
      },
    },
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  watch: {
    url() {
      this.reloading()
    },
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      rows: [],
      searchTerm: '',
      break: false,
      loading: false,
    }
  },
  computed: {
    _columns() {
      return this.columns
    },
    _rows() {
      return this.items ?? this.rows
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.reloading()
  },
  beforeDestroy() {
    this.break = true
  },

  methods: {
    async reloading() {
      const { url, method } = this
      if (!url) return ''
      this.loading = true
      const results = await this.api[method](url)
      this.loading = false
      this.rows = results
      // this.runWorker(results.next_page_url)
    },
    async runWorker(url) {
      if (url === null) return
      if (this.break) return

      const _url = url.split('/api')
      const resp = await this.api.get(`api${_url[1]}`)
      this.rows = await this.rows.concat(resp.data)
      this.runWorker(resp.next_page_url)
    },

    clickAction(clickAt) {
      // console.log(clickAt, 3)
    },

    async clickDelete(url, data) {
      await this.api.del(url, data, true)
      return this.reloading()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
