export default [
  {
    path: '/refund-management',
    name: 'refund-management',
    component: () => import('@/views/apps/admins/refund-management/RefundManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'manage',
    },
  },
]
