export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardScreen.vue'),
    meta: {
      resource: 'dashboard',
      action: 'manage',
    },
  },
  {
    path: '/how-to',
    name: 'how-to',
    component: () => import('@/views/apps/how-to/HowToScreen.vue'),
    meta: {
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/announce',
    name: 'announce',
    component: () => import('@/views/apps/announce/AnnounceScreen.vue'),
    meta: {
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/announce/:id',
    name: 'announce-details',
    component: () => import('@/views/apps/announce/AnnounceDetailsScreen.vue'),
    meta: {
      resource: 'Auth',
      action: 'manage',
    },
  },
  {
    path: '/announce/management/:id',
    name: 'announce-management',
    component: () => import('@/views/apps/announce/AnnounceManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'manage',
    },
  },
]
