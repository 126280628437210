import Vue from 'vue'
import ability from '../libs/acl/ability'

const self = Vue

export default {
  namespaced: true,
  state: {
    userInfo: '',
    isLoading: true,
  },
  getters: {
    // eslint-disable-next-line no-underscore-dangle
    _isClient(state) {
      return state.userInfo?.role === 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
    },
    // eslint-disable-next-line no-underscore-dangle
    _isPartner(state) {
      return state.userInfo?.role_data_preview === 'partner'
    },
    // eslint-disable-next-line no-underscore-dangle
    _isAdmin(state) {
      return state.userInfo?.role_data_preview === 'admin'
    },
    // eslint-disable-next-line no-underscore-dangle
    _isPartnerOrAdmin(state) {
      const roleDataId = state.userInfo?.role_data_id
      return roleDataId !== 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
    },
    // eslint-disable-next-line no-underscore-dangle
    _isMarketing(state) {
      return state.userInfo?.role_data_preview === 'marketing'
    },
    // eslint-disable-next-line no-underscore-dangle
    _userInfo(state) {
      return state.userInfo
    },
    // eslint-disable-next-line no-underscore-dangle
    _isCanManagementRedeem(state) {
      if (state.userInfo?.role_data_preview === 'admin') return true

      if (state.userInfo?.role_data_preview === 'partner' && state.userInfo?.amount_upload_per_day === 99) {
        return true
      }
      return false
    },
    // eslint-disable-next-line no-underscore-dangle
    _userRole(state) {
      return state.userInfo?.role_data_preview
    },

    // eslint-disable-next-line no-underscore-dangle
    _isPartnerCanUploadWallpaper(state) {
      if (state.userInfo.amount_upload_per_day === 99) return true

      if (state.userInfo.upload_limit_used < state.userInfo.amount_upload_per_day) return true

      return false
    },

    // เอาไว้ตรวจสอบเวลาถูกแบน จะทำให้ใช้บาง func ไม่ได้
    // eslint-disable-next-line no-underscore-dangle
    _isCanAccessSomeFeature(state) {
      if (state.userInfo?.role_data_preview === 'admin') return true

      if (state.userInfo?.amount_upload_per_day > 0) return true

      return false
    },
  },
  mutations: {
    SET_USER_INFO_V2(state, payload) {
      // console.log('SET_USER_INFO_V2', { payload })

      state.userInfo = { ...payload }
      const role = payload?.role_data_preview

      let newAbility = [
        { action: 'manage', subject: 'Auth' },
        { action: 'manage', subject: 'dashboard' },
      ]

      switch (role) {
        case 'admin':
          newAbility = [
            ...newAbility,
            { action: 'manage', subject: 'admin' },
            { action: 'manage', subject: 'marketing' },
            { action: 'manage', subject: 'wallpaper' },
            { action: 'manage', subject: 'redeem-management' },
            { action: 'manage', subject: 'tools' },
          ]
          break
        case 'partner':
          newAbility = [...newAbility, { action: 'manage', subject: 'partner-setting' }]

          if (payload?.approve_status_partner === true) {
            newAbility.push({ action: 'manage', subject: 'wallpaper' })
            newAbility.push({ action: 'manage', subject: 'partner' })
            newAbility.push({ action: 'manage', subject: 'tools' })
          }

          if (payload?.amount_upload_per_day === 99) {
            newAbility.push({ action: 'manage', subject: 'redeem-management' })
          }
          break
        case 'support':
          newAbility = [...newAbility, { action: 'manage', subject: 'support' }, { action: 'manage', subject: 'tools' }]
          break
        case 'marketing':
          newAbility = [
            ...newAbility,
            { action: 'manage', subject: 'marketing' },
            { action: 'manage', subject: 'wallpaper' },
            { action: 'manage', subject: 'tools' },
          ]
          break
        default:
          break
      }

      const userData = {
        id: payload?.id,
        fullName: payload?.name,
        username: payload?.name,
        avatar: '/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png',
        email: payload?.email,
        role,
        ability: newAbility,
        extras: { eCommerceCartItemsCount: 5 },
        approve_status_partner: payload?.approve_status_partner ?? false,
      }
      localStorage.setItem('role_wall', role)
      localStorage.setItem('userData', JSON.stringify(userData))

      ability.update(newAbility)

      state.isLoading = false
    },
    SET_USER_INFO(state, payload) {
      // console.log('SET_USER_INFO', payload)
      const info = JSON.parse(JSON.stringify({ ...payload }))
      localStorage.setItem('role_wall', info.role_data_preview)
      state.userInfo = info
      if (info.role_data_preview === 'partner') {
        const userData = localStorage.getItem('userData')
        if (!userData) return

        const userDataJson = JSON.parse(userData)

        const newAbility = [
          { action: 'manage', subject: 'dashboard' },
          { action: 'manage', subject: 'partner-setting' },
          { action: 'manage', subject: 'Auth' },
        ]

        if (info.approve_status_partner === true) {
          newAbility.push({ action: 'manage', subject: 'partner' })
          newAbility.push({ action: 'all', subject: 'wallpaper' })
        }

        if (this.getters['AuthStore/_isCanManagementRedeem']) {
          newAbility.push({ action: 'all', subject: 'redeem-management' })
        }

        userDataJson.ability = newAbility
        userDataJson.id = payload.id

        localStorage.setItem('userData', JSON.stringify(userDataJson))
        ability.update(newAbility)
      }

      state.isLoading = false
    },
    UPDATE_LIMIT_UPLOAD_WALLPAPER(state) {
      state.userInfo.upload_limit_used = state.userInfo.upload_limit_used += 1
    },
    UPDATE_IS_LOADING(state, payload) {
      state.isLoading = payload
    },
  },
  actions: {
    updateUserInfoAuthStore({ commit }, payload) {
      commit('SET_USER_INFO_V2', payload)
    },
    async getUserInfo({ commit }) {
      const resp = await self.api.get('api/user-info', false)
      commit('SET_USER_INFO_V2', resp)
    },
    setAuthIsLoading({ commit }, payload) {
      commit('UPDATE_IS_LOADING', payload)
    },
  },
}
