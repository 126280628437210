export default [
  {
    path: '/redeem-management',
    name: 'redeem-management',
    component: () => import('@/views/apps/admins/redeem-management/RedeemManagementScreen.vue'),
    meta: {
      resource: 'redeem-management',
      action: 'manage',
    },
  },
  {
    path: '/redeem-management/create/:mainWallpaperId',
    name: 'redeem-management-create',
    component: () => import('@/views/apps/admins/redeem-management/RedeemManagementCreateScreen.vue'),
    meta: {
      resource: 'redeem-management',
      action: 'manage',
    },
  },
  {
    path: '/redeem-management/details/:redeemMainId',
    name: 'redeem-management-details',
    component: () => import('@/views/apps/admins/redeem-management/RedeemManagementDetailsScreen.vue'),
    meta: {
      resource: 'redeem-management',
      action: 'manage',
    },
  },
  {
    path: '/redeem-management/use-by-details/:redeemCode/:redeemCodeId',
    name: 'redeem-management-use-by-details',
    component: () => import('@/views/apps/admins/redeem-management/RedeemManagementUseByDetailsScreen.vue'),
    meta: {
      resource: 'redeem-management',
      action: 'manage',
    },
  },
]
