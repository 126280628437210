import Vue from 'vue'
const self = Vue

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    _loading() {
      return state.loading
    }
  },
  mutations: {
    CHANG_LOADING(state) {
      state.loading = !state.loading
    }
  }

}
