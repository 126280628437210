<template>
  <b-modal
    ref="refModal"
    title="OTP"
    centered
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    ok-title="ยืนยัน OTP"
    cancel-title="ยกเลิก"
    :cancel-disabled="loading"
    :ok-disabled="loading"
    @ok="onConfirm"
    @cancel="onCancel"
    @hide="onModalHide"
    @show="onModalShow"
  >
    <div class="mb-1">
      <p class="mb-0" v-if="phoneNumber">ระบบได้ส่งรหัส OTP เพื่อยืนยันไปที่ +66{{ cPhoneNumberFormat }}</p>
      <p v-else>{{ customMessageHeaderBody }}</p>
      <small class="text-muted">รหัสอ้างอิง {{ refCode }}</small>
    </div>
    <b-overlay :show="loading" spinner-small>
      <validation-observer ref="refFormOtp">
        <b-form>
          <b-form-group label-for="otp-verify">
            <validation-provider #default="{ errors }" name="รหัสยืนยัน OTP" rules="required">
              <b-form-input
                v-model="otp"
                name="otp-verify"
                :state="errors.length > 0 ? false : null"
                placeholder="ระบุรหัสยืนยัน OTP"
                class="text-center"
                :type="inputType"
                :number="inputType === 'number'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-overlay>
    <!-- <small class="text-right d-block">รหัสจะหมดอายุใน {{ time }} วินาที</small> -->
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    refCode: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    timeCountDown: {
      type: Number,
      default: 65, // เป็น วินาที
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customMessageHeaderBody: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'number',
    },
  },
  data() {
    return {
      otp: '',
      required,
      time: 0,
      refCustomTimer: undefined,
      // expired: false,
    }
  },
  computed: {
    cPhoneNumberFormat() {
      if (this?.phoneNumber.length === 10) {
        return `${this.phoneNumber}`.substring(1)
      }

      return this.phoneNumber
    },
  },
  methods: {
    show() {
      this.otp = ''
      this.$refs.refModal.show()
    },
    hide() {
      this.$refs.refModal.hide()
    },
    onCancel() {
      this.$emit('cancel')
    },
    async onConfirm(bvEvent) {
      bvEvent.preventDefault()

      const validate = await this.$refs.refFormOtp.validate()
      if (!validate) return

      this.$emit('confirm', this.otp)
    },
    onModalHide(bvEvent) {
      // console.log('onModalHide')
      // if (bvEvent.trigger === 'ok') return
      // console.log('onModalHide')
      // clearInterval(this.refCustomTimer)
    },
    onModalShow() {
      // console.log('onModalShow')
      // this.time = this.timeCountDown
      // this.refCustomTimer = setInterval(() => {
      //   if (this.time <= 0) {
      //     clearInterval(this.refCustomTimer)
      //     this.expired = true
      //   } else {
      //     this.time -= 1
      //   }
      // }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped></style>
