<template>
  <div>
    <b-tabs pills nav-class="nav-pill-primary custom-pill-nav-tabs-translations mb-0" @activate-tab="onActiveTab">
      <b-tab v-for="lang in langsSupport" :key="lang.local_code" :title="cTitleTab(lang)" />
    </b-tabs>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    disableFetch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('app-mobile-public-configs', ['langsSupport', 'cacheTime', 'cacheDuration']),

    cTitleTab() {
      return values => `${values.flag} ${values.local_code}`.toUpperCase()
    },
  },
  created() {
    if (this.disableFetch) return
    this.fetchAppLangsSupport()
  },
  methods: {
    ...mapActions('app-mobile-public-configs', ['fetchAppLangsSupport']),

    onActiveTab(newTabIndex) {
      // console.log('onActiveTab', newTabIndex)
      this.$emit('onChange', this.langsSupport[newTabIndex])
    },
  },
}
</script>

<style lang="scss">
.nav-pills.custom-pill-nav-tabs-translations {
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>
