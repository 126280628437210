import Vue from 'vue'
import dayjs from 'dayjs'

const self = Vue

const _state = () => {
  return {
    form: {
      userId: '',
      select: '',
    },
    results: '',
    loading: false,
    reloadQuery: false,
    options: [],
    loadings: {
      // หน้าหลักตั้งแต่ โหลดเข้ามาจาก login
      main: false,
    },
  }
}

export default {
  namespaced: true,
  state: _state(),
  getters: {
    _partnerInfo(state) {
      return state.results?.userInfo
    },
    _isClient(state) {
      return state.results?.userInfo?.role_data_id == 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
    },
    _isPartner(state) {
      const roleDataId = state.results?.userInfo?.role_data_id
      return roleDataId == '974f843e-8b0e-4221-8bc5-767276a9f98e'
    },
    _isAdmin(state) {
      const roleDataId = state.results?.userInfo?.role_data_id
      return roleDataId == '7fa970cc-aa32-49bc-9cf6-50f996bc6385'
    },
    _isPartnerOrAdmin(state) {
      const roleDataId = state.results?.userInfo?.role_data_id
      return roleDataId != 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
    },
  },
  mutations: {
    CLEAR_DATA(state) {
      try {
        Object.assign(state, _state())
        state.loading = false
      } catch (error) {}
    },
    CHANGE_RELOAD_QUERY(state) {
      state.reloadQuery = !state.reloadQuery
    },
    async QUERY_RESULTS(state) {
      state.loadings.main = false

      let url = `api/admin/dashboard/index`
      if (state.loading == true) {
        url = `${url}?dateFilter=${state.form.select}`
      }

      if (state.form.userId) {
        if (url.split('?').length > 1) {
          url = `${url}&userId=${state.form.userId}`
        } else {
          url = `${url}?userId=${state.form.userId}`
        }
      }

      await self.api
        .get(url, false)
        .then(result => {
          state.results = result
          state.loading = true
        })
        .catch(err => {})
      state.reloadQuery = false
      state.loadings.main = true
    },
    async QUERY_DATE(state) {
      const isPartner = this.getters['AuthStore/_isPartner']

      const userId = this.getters['AuthStore/_userInfo']?.id

      let fetchId = ''

      if (isPartner && userId) {
        fetchId = userId
      }

      let url = 'api/dashboard/fetch-date'

      if (fetchId) {
        url = `${url}?userId=${fetchId}`
      }

      const resp = await self.api.getV2(url).catch(() => null)
      // console.log('QUERY_DATE', resp)

      if (resp && resp?.code === 200 && Array.isArray(resp.data) && resp.data.length > 0) {
        const { data } = resp
        const sortDateArr = [...data].sort((a, b) => new Date(b) - new Date(a))
        state.options = sortDateArr
        // eslint-disable-next-line prefer-destructuring
        state.form.select = sortDateArr[0]
      } else {
        const defaultMonthYear = dayjs().format('YYYY-MM-01')
        state.options = [defaultMonthYear]
        state.form.select = defaultMonthYear
      }

      state.loading = true
    },
  },
  actions: {
    async fetchData({ commit }) {
      await commit('QUERY_DATE')
      await commit('QUERY_RESULTS')
    },
  },
}
