export default [
  {
    path: '/export-management',
    name: 'export-management',
    component: () => import('@/views/apps/admins/export-management/ExportManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'manage',
    },
  },
]
