export default [
  {
    path: '/images-management/approve',
    name: 'images-management-approve',
    component: () =>
      import('@/views/apps/admins/images-management/images-management-approve/ImagesManagementApprove.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/images-management/review',
    name: 'images-management-review',
    component: () =>
      import('@/views/apps/admins/images-management/images-management-review/ImagesManagementReview.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/images-management/checking',
    name: 'images-management-checking',
    component: () =>
      import('@/views/apps/admins/images-management/image-management-checking/ImageManagementCheckingScreen.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/images-management/reject',
    name: 'images-management-reject',
    component: () =>
      import('@/views/apps/admins/images-management/images-management-reject/ImagesManagementReject.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
  {
    path: '/images-management/details/:id',
    name: 'images-management-details',
    component: () =>
      import('@/views/apps/admins/images-management/images-management-details/ImagesManagementDetails.vue'),
    meta: {
      resource: 'marketing',
      action: 'manage',
    },
  },
]
