import dayjs from 'dayjs'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    langsSupport: [
      {
        flag: '🇹🇭',
        label: 'ไทย',
        local_code: 'th',
      },
    ],
    cacheTime: null,
    cacheDuration: 60, // in minute
  },
  getters: {},
  mutations: {
    UPDATE_APP_MOBILE_LANGS_SUPPORT(state, { listData, cacheDateTime }) {
      state.langsSupport = [...listData]
      state.cacheTime = cacheDateTime
    },
  },
  actions: {
    async fetchAppLangsSupport({ state, commit }) {
      const diffDate = dayjs().diff(state.cacheTime, 'minute')

      if (state.cacheTime && diffDate < state.cacheDuration) {
        return
      }

      // await new Promise(r => setTimeout(r, 5000))

      const resp = await Vue.api
        .getV2('/api/application/config?key=1134f13a-4cba-4bc0-bb18-df7d70d28c92')
        .catch(() => null)
      // console.log('fetchAppLangsSupport', resp)

      if (resp && typeof resp === 'object' && Array.isArray(resp?.langs_support)) {
        // console.log('fetchAllLangSupport => ', resp)
        commit('UPDATE_APP_MOBILE_LANGS_SUPPORT', { listData: [...resp.langs_support], cacheDateTime: dayjs() })
      } else {
        // console.log('fetchAllLangSupport => error')
      }
    },
  },
}
